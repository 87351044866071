Vue.component('game-card', {
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  template: `
  <div class="game-card-container">
    <div>
      <div class="series-name">{{ seriesTitle }}</div>
      <div class="kickoff">{{ kickoff }}</div>
      <div class="scores">
        <div>-</div>
        <div v-for="item in game.gameRecords" :key="item._id">
          <div class="score">{{ item.score }}</div>
          <div class="team">{{ item.team.display }}</div>
        </div>
      </div>
    </div>
  </div>`,
  computed: {
    series() {
      return this.game.series || {}
    },
    seriesTitle() {
      return `${this.series.year}年度 ${this.series.shortName} ${this.unit}`
    },
    unit() {
      return this.series.type === 'league' ? `第${this.game.section}節` : `${this.game.section}回戦`
    },
    kickoff() {
      return dayjs(this.game.date).format('YYYY.MM.DD(dd) HH:mm')
    },
  },
})
